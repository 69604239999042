.container {
  // font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 1003px; // widest video is 903 + 50 foreach border
}
.leftToRight {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
}
.middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 100px);
}
.topBorder {
  margin: 10px 0px 10px 0px;
  background-image: url(../../images/ornaments-left-to-right4.jpg);
  background-repeat: repeat-x;
  background-size: 112px 44px;
  width: 93%;
  height: 50px;
}
.leftBorder {
  margin: 0px 0px 0px 0px;
  background-image: url(../../images/ornaments-bottom-to-top.jpg);
  background-repeat: repeat-y;
  background-size: 44px 112px;
  width: 50px;
}
.rightBorder {
  margin: 0px 0px 0px 0px;
  background-image: url(../../images/ornaments-top-to-bottom.jpg);
  background-repeat: repeat-y;
  background-size: 44px 112px;
  width: 50px;
}
.bottomBorder {
  margin: 10px 30px 10px 30px;
  background-image: url(../../images/ornaments-right-to-left.jpg);
  background-repeat: repeat-x;
  background-size: 112px 44px;
  width: 93%;
  height: 50px;
}
.greeting {
  margin-bottom: 25px;
}
.preQuiz {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.preQuizForm {
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 20px;
}
.introButton {
  margin-bottom: 10px;
}
.quizIntroduction {
  font-size: large;
  margin-bottom: 20px;
}
.successPictures {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.successImage {
  margin: 10px;
  max-width:600px;
  max-height:600px;
  width:auto;
  height:auto;
}
.successIFrame {
  margin: 10px;
}
